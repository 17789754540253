
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        








































































































































































.s-info__skeleton-mb {
  margin-bottom: 6px;
}

.sas-info {
  @include flex-center-start();

  &__icon {
    flex-shrink: 0;

    &.--x-small,
    &.--small,
    &.--medium {
      margin-right: $size-xs;
    }

    &.--large,
    &.--x-large {
      margin-right: $size-s;
    }
  }

  &__label {
    font-size: $font-size-xs;
    font-weight: $font-weight-semi-bold;
    color: $color-ink-light;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.--x-large {
      font-size: $font-size-m;
      text-transform: none;
      letter-spacing: .4px;
    }

    &.--x-small{
      font-size: $font-size-xxs;
    }
  }
  &__text {
    &.--x-large {
      font-size: $font-size-heading-1;
      font-weight: $font-weight-bold;
    }

    &.--large {
      font-size: $font-size-heading-3;
      font-weight: $font-weight-bold;
    }

    &.--medium {
      font-size: $font-size-heading-5;
      font-weight: $font-weight-bold;
    }

    &.--small {
      font-size: $font-size-m;
      font-weight: $font-weight-semi-bold;
    }

    &.--x-small {
      font-size: $font-size-s;
      font-weight: $font-weight-semi-bold;
    }
  }
}

