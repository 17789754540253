
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        















































































.sas-box {
  @include transition($speed-fast);

  &:focus {
    @include input-focus-outline();
  }

  &.--elevation-level-0 {
    border: 1px solid rgba($color-ink, .15);
    box-shadow: none;
  }

  @each $level, $box-shadow in $shadows {
    &.--elevation-level-#{$level} {
      box-shadow: $box-shadow;
    }

    &.--hover-#{$level}:hover {
      box-shadow: $box-shadow;
    }
  }
}
