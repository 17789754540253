
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        
































































































































































































































.sas-dropdown {
  position: relative;
  display: inline-block;

  &:focus {
    outline: 0;
  }

  &.--loading {
    pointer-events: none;
    cursor: progress;

    .sas-button__icon {
      opacity: 0;
    }
  }

  &__button {
    justify-content: space-between !important;
  }

  &__spinner {
    position: absolute;
    right: 8px;
    bottom: 6px;
    z-index: 100;
  }
}
