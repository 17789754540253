
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        


















































































































































































































.sas-select {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;

  &:focus {
    outline: 0;
  }

  &__dropdown {
    display: block;
    width: 100%;
    max-width: 100%;

    .sas-dropdown-menu {
      position: absolute;
      justify-content: unset;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
    }

    &.--empty:not(.--primary) .sas-dropdown__button {
      color: $color-ink-lighter;
      @include transition($speed-x-fast);
    }

    .sas-dropdown__button:not(.--primary),
    .sas-dropdown-menu__item {
      color: $color-ink;
      box-shadow: none !important;

      b {
        font-weight: $font-weight-semi-bold;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: $size-xxs;
    color: $color-ink-light;
  }

  &__search {
    width: 100%;
    margin-top: -$size-xxs;
    margin-bottom: $size-xxs;
    @include space-inset(12px);
  }

  &__option {
    &.--selected {
      background: rgba($color-ink, .15);

      &:hover {
        background: rgba($color-ink, .25);
      }
    }
  }

  &__empty-search {
    max-width: 320px;
    color: $color-ink-lighter;
    @include space-inset($size-s);

    strong {
      color: $color-ink-light;
    }
  }

  &__search-box {
    width: 100%;
    padding: $size-s;
  }

  .sas-button__text {
    font-weight: $font-weight-regular;
  }
}
