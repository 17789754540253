
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        
















































.sas-dropdown-menu {
  position: absolute;
  z-index: 100;
  border: 1px solid rgba($color-ink, .25);
  @include flex-column-start();
  @include transition($speed-fast);

  // --------------------------------------------------------------
  // Origins
  // --------------------------------------------------------------
  &.--bottom-right {
    top: 100%;
    right: 0;
  }

  &.--bottom-left {
    top: 100%;
    left: 0;
  }

  &.--top-right {
    right: 0;
    bottom: 100%;
  }

  &.--top-left {
    bottom: 100%;
    left: 0;
  }

  // --------------------------------------------------------------
  // Animation
  // --------------------------------------------------------------
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-10%);
  }

  &-leave-to {
    transition-delay: $speed-x-fast;
  }
}
