
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        









































































































































.sas-modal {
  position: relative;
  z-index: 3;
  @include transition($speed-normal);

  &__container {
    backface-visibility: hidden;
    z-index: 9999;
    @include flex-center();
    @include cover(fixed);
  }

  &__overlay {
    background: rgba($color-ink, .5);
    @include cover(fixed);
    @include transition($speed-slow);
  }

  &__close-button {
    position: absolute !important;
    top: $size-s;
    right: $size-s;
  }

  &__header {
    padding: $size-m $size-l;
    padding-bottom: $size-xs;
  }

  &__body {
    padding: $size-m $size-l;
  }

  &__footer {
    padding: $size-s $size-l;
    @include flex-center();
  }

  // -------------------------------------------------------
  // Animations
  // -------------------------------------------------------
  &-enter {
    .sas-modal{
      transform: scale(.8) translateY(15%) rotate3d(1, 0, 0, 20deg);
      transform-origin: center;

      &,
      &__overlay {
        opacity: 0;
      }
    }
  }

  &-leave-to {
    transition-delay: $speed-slow;

    .sas-modal {
      transform: scale(.9) translateY(15%);
      transform-origin: center;

      &,
      &__overlay {
        opacity: 0;
        transition-duration: $speed-slow;
      }
    }
  }
}
