
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        



































































































































// stylelint-disable-next-line selector-no-qualifying-type
input[type="checkbox"] {
  position: relative;
  width: $size-m;
  height: $size-m;
  margin-right: $size-xs;
  background-color: $color-white;
  border: 1px solid $color-ink-lighter;
  border-radius: 4px;
  appearance: none;
  @include transition(200ms);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    content: "";
    border-radius: 4px;
    background: {
      position: center;
      image: url("~@/assets/check.svg");
      repeat: no-repeat;
      size: 0%;
    }
    @include transition(200ms);

    &:not(:checked):not(:disabled):not(:focus) {
      box-shadow: inset 0 2px 2px rgba(30, 33, 36, .2);
    }
  }

  &:checked {
    background-color: $color-primary;
    border: none;

    &::before {
      background-size: 90%;
    }

    &:focus,
    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &:not(:checked):not(:disabled):not(:focus):hover {
    border-color: $color-ink-light;
  }

  &:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 3px $color-primary-light;
  }

  &:disabled {
    cursor: default;
    background-color: #e5e5e5;
    border-color: $color-ink-lightest;
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  .s-checkbox {
    display: inline-flex;
    align-items: center;

    &__input {
      position: relative;
      width: $size-m;
      height: $size-m;
      margin-right: $size-xs;
      background-color: $color-white;
      border: 1px solid $color-ink-lighter;
      border-radius: 4px;
      appearance: none;
      @include transition(200ms);

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        content: "";
        border-radius: 4px;
        background: {
          position: center;
          repeat: no-repeat;
          size: 0%;
        }
        @include transition(200ms);

        &:not(:checked):not(:disabled):not(:focus) {
          box-shadow: inset 0 2px 2px rgba(30, 33, 36, .2);
        }
      }

      &:checked {
        background-color: $color-primary;
        border: none;

        &::before {
          background: {
            image: url("~@/assets/check.svg") !important;
            size: 90%;
          }
        }

        &:focus,
        &:hover {
          background-color: $color-primary-dark;
        }
      }

      &:not(:checked):not(:disabled):not(:focus):hover {
        border-color: $color-ink-light;
      }

      &:focus {
        border-color: transparent;
        outline: 0;
        box-shadow: 0 0 0 3px $color-primary-light;
      }

      &:disabled {
        cursor: default;
        background-color: #e5e5e5;
        border-color: $color-ink-lightest;
      }

      // stylelint-disable-next-line selector-no-qualifying-type
      &--indeterminate[type="checkbox"] {
        background-color: $color-primary;
        border: none;

        &:hover {
          background-color: $color-primary-dark;
        }

        &::before {
          background: {
            image: url("~@/assets/minus.svg");
            size: 70%;
          }
        }
      }
    }

    &__label {
      font-size: $font-size-m;
      color: $color-ink;

      &--disabled {
        color: $color-ink-lightest;
      }
    }
  }
}
