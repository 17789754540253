
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        






























































































.drawer-wrapper {
  position: fixed;
  z-index: $zindex-modal;
  transition: all 400ms ease-in-out;
}

.drawer {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: $color-ice;
  backface-visibility: hidden;
  @extend .drawer-wrapper;

  &.--position {
    &-top {
      bottom: auto;
    }

    &-left {
      right: auto;
    }

    &-right {
      left: auto;
    }

    &-bottom {
      top: auto;
    }
  }

  &__overlay {
    background: rgba($color-ink, .5);
    @include cover(fixed);
    @include transition($speed-slow);
  }

  &-enter,
  &-leave-to {
    .drawer.--position {
      &-top {
        transform: translateY(-100%);
      }

      &-bottom {
        transform: translateY(100%);
      }

      &-left {
        transform: translateX(-100%);
      }

      &-right {
        transform: translateX(100%);
      }
    }

    .drawer__overlay {
      opacity: 0;
    }
  }
}
