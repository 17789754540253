
          @import "@sas-te/alfabeto-tokens/src/scss/functions";
          @import "@sas-te/alfabeto-tokens/src/scss/variables";
          @import "@sas-te/alfabeto-tokens/src/scss/mixins";
        
































.sas-wrapper {
  max-width: 1032px;
  margin: 0 auto;

  @include mq_xl() {
    max-width: calc(100% - 80px);
  }

  @include mq_l() {
    max-width: calc(100% - 80px);
  }

  @include mq_m() {
    max-width: calc(100% - 64px);
  }

  @include mq_s() {
    max-width: calc(100% - 32px);
  }

  &.--full-width {
    max-width: 100%;
  }
}
